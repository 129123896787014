import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileLikeObject, FileUploader } from "ng2-file-upload";
import { PrescriptionService } from 'src/app/services/prescription.service';

import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-common-file-upload',
  templateUrl: './common-file-upload.component.html',
  styleUrls: ['./common-file-upload.component.scss']
})
export class CommonFileUploadComponent implements OnInit {
  public serverPath = environment.commonImageApi;
  @Input() type: any = "logo";
  @Input() typeOfLogo: any = "logoBG";
  @Input() productId: any;
  @Output() fileUploadResult = new EventEmitter();
  imageChangedEvent: any = '';
  imageCropper: any
  data1: any;
  data2: any;
  files1: [{}];
  files: any[] = [];
  fileName: any;
  row: any;
  uploadmsg: any;
  text: string;
  item: FileLikeObject;

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }


  constructor(

    private fileUploadService: PrescriptionService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.s();
    this.text = "Upload all";

  }

  getClass(i) {
    if (i == 'banner') return 'banner'
    else if (i == 'multi') return 'multi'
  }

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }
  fileOverBase(event) {

  }
  fileOverAnother(event) {

  }


  upload() {

    this.text = "Processing";
    let files = this.getFiles();
    let data = new FormData();
    if (files.length > 0) {
      files.forEach((file:any) => {

        if (this.type == 'logo') {
          data.append('files', file['rawFile'], this.typeOfLogo);
        } else {
          data.append('files', file['rawFile'], file.name.replace(/\s/g, "_").replace(/\(/g, '_').replace(/\)/g, '_'));
        }

      });
      // POST formData to Server

      this.fileUploadService.normalUploadFiles(data, this.type).subscribe(
        res => {
          this.data1 = res;
          this.snackBar.open('Uploaded Successfully ', 
          '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
          this.fileUploadResult.emit(res);
          this.text = "Upload all";
          if (this.type == 'logo') {
            this.myInputVariable.nativeElement.value = "";

          }
          this.uploader.clearQueue();


        }, error => {
          this.text = "Upload all";
        }
      );


    } else {
      this.text = "Upload all";
      alert("No Items Found")
    }
  }



  public uploader: FileUploader = new FileUploader({
    url: 'your_upload_url_here', 
    isHTML5: true,
    allowedFileType: ['image'],
    //maxFileSize: 5 * 1024 * 1024
  });

 
  @ViewChild("myInput")
  myInputVariable: ElementRef;


  s() {


    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      let message = '';
      let maxFileSize = 5 * 1024 * 1024;
      let queueLimit = 5;
      this.item = item;
      switch (filter.name) {
        case 'fileType':

          this.snackBar.open("uploaded wrong file type, " + item.name, '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          this.myInputVariable.nativeElement.value = "";
          this.uploader.clearQueue();
          break;
        case 'fileSize':
          this.snackBar.open("  The file could not be uploaded because it exceeds 5mb,the maximum allowed size for uploads ", '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          this.myInputVariable.nativeElement.value = "";
          this.uploader.clearQueue();
          break;
        default:
          break;
      }
    };

  }



}
