import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FieldConfig } from '../field.interface';

@Component({
  exportAs: "dynamicForm",
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {
  @Input() fields: FieldConfig[] = [];

  @Input() layout:any=2;

  @Input() gapBetweenImage:any='5px';

  @Input() displayMobileImage:any=2;

  @Input() mobileGapBetweenImage:any=2;
  
  minusValue:any='2px';

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  formInit: boolean=false;

  get value() {
    return this.form.value;
  }
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {    
    this.form = this.createControl();
    this.formInit=true;
  }

  onSubmit() {
    
    if (this.form.valid) {
      this.submit.emit(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field['dynamicFormLabelValidator'] || [])
      );
      group.addControl(field.name, control);
    });
    return group;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        let validator:any=''
        if(valid.name=='required'){
          validator=Validators.required  
          validator.validator=Validators.required
          validList.push(validator);
        }else if(valid.name=='pattern'){
          validator=Validators.pattern(valid.validator);
          validList.push(validator);
        }
       
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
  calculateFlexValue(layout: number, gapBetweenImage: boolean): string {
    const baseFlex = layout === 2 ? '50%' : 
                     layout === 3 ? '33.33%' : 
                     layout === 4 ? '25%' : '100%';
 
                    
                     
    return gapBetweenImage ? `calc(${baseFlex} - ${gapBetweenImage})` : baseFlex;
 }
}
