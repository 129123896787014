
<div *ngIf="imageVisible" style="border: 1px dotted #ddd;
border-radius: 0px;padding: 15px 10px;" fxLayout="row"
  fxLayoutAlign="space-between center" fxLayoutGap="10px">
  <div fxLayout="column">    

  <div fxLayout="row" fxLayoutGap="10px">

    <!-- <span fxLayoutAlign="start center">
        {{value}}
        <button (click)="download()"
        color="warn" mat-icon-button type="button" >
        <mat-icon>download

            </mat-icon>

        </button>

    </span> -->

    <span fxLayoutAlign="start center" style="display: inline-block;">
      <img [src]="serverPath+value" style="height: 50px; width: auto; display: inline-block;"
        onerror="this.src='assets/images/no-image.png';">
      {{value}}
    </span>

    <ng-container fxLayoutAlign="end center">
        <button (click)="logo()" mat-raised-button>Upload</button>

        <!-- <button *ngIf="value!=''" (click)="deleteImage(0)"
         mat-raised-button>delete</button> -->
    </ng-container>

  </div>
</div>
</div>

<div *ngIf="!imageVisible&&!imageDelete" style="border: 1px dotted #ddd;border-radius: 0px;padding: 15px 10px;" fxLayout="row"
  fxLayoutAlign="space-between center" fxLayoutGap="10px">
  <calsoft-common-file-upload (fileUploadResult)=getResult($event) 
  [type]="'gallery'">
  </calsoft-common-file-upload>
  <button (click)="cancelUpload()" mat-raised-button>Cancel</button>
</div>
