import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constant } from 'src/app/constants/constant';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { FieldConfig } from '../field.interface';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  field: FieldConfig;
  group: UntypedFormGroup;
  text: string;
  public serverPath = environment.commonImageApi + 'gallery/';
  value: any;
  constructor(
    private snackBar: MatSnackBar,
    private productService: ProductService,) { }

  ngOnInit(): void {
    this.text = "Save"
    this.value= this.group.value[this.field.name];
  }
  imageVisible: boolean = true;
  imageDelete: boolean;
  imagesToRemoved: any = [];
 
  // deleteImage(i) {
  //   this.imageVisible = false;
  //   this.imageDelete = true;
  //   let smallImage = "/files/" + this.group.value[this.field.name];
  //   this.imagesToRemoved.push(smallImage);

  //   this.productService.deleteImage(this.imagesToRemoved).subscribe(
  //     (response) => {
  //       if (response['status_code'] == Constant.RESPONSE_SUCCESS) {

  //         this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
  //         this.text = "Save"
  //         this.imageVisible = false;
  //         this.imageDelete = false;
          
  //       }
  //       else {
  //         this.imageVisible = true;
  //         this.imageDelete = false;
  //         this.group.value[this.field.name]='';
  //         this.group.patchValue(this.group.value)
  //         this.text = "Save"
  //         this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
  //       }
  //     },
  //     (err) => {
  //       this.imageVisible = true;
  //     });

  // }

  getResult(event) {
    this.imageVisible = true;

    this.group.value[this.field.name]=event.productEntityMediaGallery[0].fileName
    this.group.patchValue(this.group.value)
    this.value= this.group.value[this.field.name];
  }
  cancelUpload() {
    this.imageVisible = true;
  }

  logo() {
    this.imageVisible = false;
  }


}
