<!-- <div *ngFor="let value of arry" fxLayout="row" fxLayoutAlign="start start" style="margin: 0; padding: 0;">
  <div *ngIf="icon" [fxFlex]="icon ? '5%' : '0'" [fxFlex.xs]="icon ? '10' : '0'" style="margin: 0; padding: 0;">
    <mat-icon style="padding: 5px 0px;" class="maticoncss">{{ matIcon }}</mat-icon>
  </div>
  <div [fxFlex]="icon ? '95%' : '100%'" style="margin: 0; padding: 0;">
    <calsoft-editor-text-child
      [data]="data"
      [text]="value"
      [align]="align"
      [fontSize]="fontSize"
      [MobfontSize]="MobfontSize"
      [fontFamily]="fontFamily">
    </calsoft-editor-text-child>
  </div>
</div> -->



<div *ngFor="let value of arry" fxLayoutGap="10px" fxLayout="row" style="padding-top:5px" fxLayoutAlign="start start" >
  <div fxLayout="column" style="width:100%"   fxLayoutGap="10px">

    <div fxLayout="row" fxLayoutGap="10px"  >

      <mat-icon *ngIf="icon"   class="maticoncss">{{ matIcon }}</mat-icon>
    
      <calsoft-editor-text-child style="width: 100%" fxLayoutAlign="start center"
      [data]="data"
      [text]="value"
      [align]="align"
      [fontSize]="fontSize"
      [MobfontSize]="MobfontSize"
      [fontFamily]="data?.websiteFontFamily">
    </calsoft-editor-text-child>

      </div>

  </div>

</div>