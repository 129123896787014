<ng-container *ngIf="data.categoryMenuType==2;">


	<div class="theme-container" style="white-space: normal;" *ngIf="display" >

		<ng-container *ngFor="let menu1 of categoryArray;" >

			<span  *ngIf="data.categoryImageDisplayHomepage==0">

				<span *ngIf="menu1.subcategories.length > 0">
					<button #button 	
					mat-button [matMenuTriggerFor]="panel.menu" #menuTrigger="matMenuTrigger"
					(click)="openMegaMenu()"><span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName | uppercase}}</span>
						<mat-icon [style.color]="data.categoryColor" style="font-size: 20px;" >keyboard_arrow_down</mat-icon>
					</button>			
				</span>
	
				<span *ngIf="menu1.subcategories.length == 0">
					<a mat-button  routerLinkActive="active-link"
						(click)="goToCategory(menu1)">
						
						<span [style.color]="data.categoryColor" class="menuSize theme-container">{{menu1.categoryName | uppercase}}</span>
					</a>
				</span>

			</span>



			<span  *ngIf="data.categoryImageDisplayHomepage==1">
				<span *ngIf="menu1.subcategories.length > 0">
					<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu1.image" [alt]="menu1.categoryName">
					<button #button 	
					mat-button [matMenuTriggerFor]="panel.menu" #menuTrigger="matMenuTrigger"
					(click)="openMegaMenu()"><span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName | uppercase}}</span>
						<mat-icon [style.color]="data.categoryColor" style="font-size: 20px;">keyboard_arrow_down</mat-icon>
					</button>
					
				</span>
	
				<span *ngIf="menu1.subcategories.length == 0">
					<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu1.image" [alt]="menu1.categoryName">
					<a mat-button  routerLinkActive="active-link"
						(click)="goToCategory(menu1)">
						
						<span [style.color]="data.categoryColor" class="menuSize theme-container">{{menu1.categoryName | uppercase}}</span>
					</a>
				</span>

			</span>
		
			
			<span >
				
<calsoft-menu2   [data]="data" [display]="display" yPosition="below" 
 #panel  [items$]="menu1.subcategories"></calsoft-menu2> 

</span>
</ng-container>
</div>


<div class="backMenu theme-container"  fxHide.lt-md *ngIf="!display">

	<mat-toolbar class="top-navbar theme-container backMenu"[color]="color ? 'primary' : null" 
	
	  fxLayoutAlign="center center">
		<ng-container class="fill-remaining-space" *ngFor="let menu1 of categoryArray;">
			<span *ngIf="menu1.subcategories.length > 0">
				<a    class="hover1" mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
					(click)="openMegaMenu()"><span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName | uppercase}}</span>
					<mat-icon [style.color]="data.categoryColor" style="font-size: 20px;" class="caret">keyboard_arrow_down</mat-icon>
				</a>
			</span>
			<a mat-button *ngIf="menu1.subcategories.length == 0" routerLinkActive="active-link"
				(click)="goToCategory(menu1)">
				<span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName | uppercase}}</span>
			</a>
			<span (mouseleave)="menuTrigger.closeMenu()"></span>

			<mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu theme-container app-dropdown">

				<div fxLayout="row wrap" fxLayout.xs="column wrap">

					<div [style.column-count]="menu1.mega != 'false'?data.categroySubmenuColumn:''" >


						<span *ngFor="let submenu1 of menu1.subcategories">

							<a (click)="goToCategory(submenu1)" mat-menu-item
								routerLink="/"><span [style.color]="data.categoryColor">{{submenu1.categoryName}}</span>
			
								<i [style.color]="data.categoryColor" class="material-icons theme-container subMenus">keyboard_arrow_right</i>
							
							</a>


								<calsoft-nested-child [data]="data" [type]="'ipad'" [list]="submenu1.subcategories"></calsoft-nested-child>

							<!-- <span *ngIf="submenu1.subcategories && submenu1.subcategories.length >0">

								<a *ngFor="let subChild of submenu1.subcategories" (click)="goToCategory(subChild)"
									mat-menu-item routerLink="/"> {{subChild.categoryName}}</a>
							</span> -->


						</span>
					</div>

				</div>

			</mat-menu>
		</ng-container>
	</mat-toolbar>
</div>
		

</ng-container>

<ng-container  *ngIf="data.categoryMenuType==1;">


  <nav class="app-nav" style="white-space: normal;" *ngIf="display" >
	<ul ngClass.lt-lg="catNames" class="app-nav-list">

	
		<li class="app-nav-item" *ngFor="let menu of categoryArray" [ngClass]="{'mega': menu.mega != 'false'}">


			<div *ngIf="menu.displayMode==6; else notButton">
			
					  <calsoft-button-category [menuData]="menu"></calsoft-button-category>
			</div>

			<ng-template #notButton>
			<span *ngIf="data.categoryImageDisplayHomepage==0">

				
				<ng-container *ngIf="menu.subcategories.length > 0">
					<a [style.color]="data.categoryColor" (click)="goToCategory(menu)" style="display: flex; align-items: center;font-size: 15px;line-height: 30px;" class="pointer" routerLinkActive="active-link">
					  {{menu.categoryName}}
					  <mat-icon [style.color]="data.categoryColor" style="font-size: 20px;">keyboard_arrow_down</mat-icon>
					
					</a>
				  </ng-container>				  
	
				<a  [style.color]="data.categoryColor" class="nav-link  pointer" (click)="goToCategory(menu)"
				 *ngIf="menu.subcategories.length == 0" style="font-size: 15px;line-height: 30px;">
					{{menu.categoryName }}
				</a>
			</span>

			<span *ngIf="data.categoryImageDisplayHomepage==1">
				<div style="display: flex; align-items: center;font-size: 15px;line-height: 30px;" *ngIf="menu.subcategories.length > 0">
					<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu.image" [alt]="menu.categoryName">
					<a [style.color]="data.categoryColor" class="nav-link pointer" (click)="goToCategory(menu)" >{{ menu.categoryName }}</a>
				  </div>
	
				
				<div style="display: flex; align-items: center;font-size: 15px;line-height: 30px;" *ngIf="menu.subcategories.length == 0">
					<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu.image" [alt]="menu.categoryName">
					<a [style.color]="data.categoryColor" class="nav-link pointer" (click)="goToCategory(menu)" >{{ menu.categoryName }}</a>
				  </div>
			</span>
	

			<div class="sub-menu " style="z-index: 1000;max-height: 70vh;overflow-y: auto;" *ngIf="menu.subcategories && menu.subcategories.length >0 "
				[ngStyle]="{'width': menu.mega == 'false' ? 'max-content' : ''}">

				<div [style.column-count]="menu.mega != 'false'?data.categroySubmenuColumn:''" >
					<div *ngFor="let submenu1 of menu.subcategories">

						<ng-container>
							<div fxLayout="row" style="align-items: center;">
								<span (click)="goToCategory(submenu1)"
								 style="font-size: 15px;line-height: 30px;" class="subMenus">
								  {{submenu1.categoryName}}
								</span>
								<mat-icon style="vertical-align: middle;font-size: 20px;margin-top: 5px;">keyboard_arrow_right</mat-icon>
							  </div>
							  
							<div fxLayout="row" fxLayoutAlign="start center">
								<calsoft-nested-child  [data]="data" [type]="'all'" [list]="submenu1.subcategories"></calsoft-nested-child>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</ng-template>
		
		</li>
	</ul>
</nav>

<div fxHide.lt-md *ngIf="!display">
	<mat-toolbar [color]="color ? 'primary' : null"  class="top-navbar" fxLayoutAlign="center center">
		<ng-container class="fill-remaining-space" *ngFor="let menu1 of categoryArray;">

	
			<div *ngIf="menu1.displayMode==6; else notButton">

				
				<!-- <calsoft-menu-button [data]="menu1"></calsoft-menu-button> -->
							</div>

							<ng-template #notButton>
			<span *ngIf="menu1.subcategories.length > 0">
				<a    class="hover1" mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
					(click)="openMegaMenu()"><span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName}}</span>
					<mat-icon [style.color]="data.categoryColor" style="font-size: 20px;" >keyboard_arrow_down</mat-icon>
				</a>
			</span>
			<a mat-button *ngIf="menu1.subcategories.length == 0" routerLinkActive="active-link"
				(click)="goToCategory(menu1)">
				<span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName}}</span>
			</a>
			<span (mouseleave)="menuTrigger.closeMenu()"></span>

			<mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu app-dropdown">

				<div fxLayout="row wrap" fxLayout.xs="column wrap">

					<div [style.column-count]="menu1.mega != 'false'?data.categroySubmenuColumn:''">


						<span *ngFor="let submenu1 of menu1.subcategories">

							<a (click)="goToCategory(submenu1)" mat-menu-item
								routerLink="/"><span [style.color]="data.categoryColor" >{{submenu1.categoryName}}</span>
			
								<i [style.color]="data.categoryColor" class="material-icons subMenus">keyboard_arrow_right</i>
							
							</a>


								<calsoft-nested-child [data]="data" [type]="'ipad'" [list]="submenu1.subcategories"></calsoft-nested-child>

							<!-- <span *ngIf="submenu1.subcategories && submenu1.subcategories.length >0">

								<a *ngFor="let subChild of submenu1.subcategories" (click)="goToCategory(subChild)"
									mat-menu-item routerLink="/"> {{subChild.categoryName}}</a>
							</span> -->


						</span>
					</div>

				</div>

			</mat-menu>
			</ng-template>
		</ng-container>
	</mat-toolbar>
</div>

</ng-container>

<ng-container *ngIf="data.categoryMenuType==3;">
	<div class="topnav">
		<div class="dropdown" *ngFor="let menu of categoryArray">
			<span fxLayoutAlign="center center" (click)="goToCategory(menu)" >{{menu.categoryName}}
			<mat-icon  [style.color]="data.categoryColor"  *ngIf="menu.subcategories.length > 0" >keyboard_arrow_down</mat-icon>
			</span>

			<div class="dropdown-content contentdrop1"
			[ngStyle]="{'left': menu.mega === 'false' ? 'auto' : '2%', 'width': menu.mega === 'false'? '100px' : 'auto'}"
			*ngIf="menu.subcategories.length > 0"  >
				<div class="header">
					<div fxLayout="row" >
						<div fxLayout-align="center center" fxLayout="column" *ngFor="let submenu1 of menu.subcategories;let odd = odd;let even = even" [ngClass]="{'odd-column': odd, 'even-column': even}">
							<span class="menu-sub1" style="border-bottom: none;" (click)="goToCategory(submenu1)"><b>{{submenu1.categoryName }}</b></span>
							<div>
								<calsoft-nested-child  [data]="data" [type]="'all'" [list]="submenu1.subcategories"></calsoft-nested-child>							
							</div>
						</div>
					</div>
				</div>   
			</div>
		</div>
	</div> 


</ng-container>

<ng-container *ngIf="data.categoryMenuType==4;">
	<div class="topnav">
		<div class="dropdown" *ngFor="let menu of categoryArray">

			<div *ngIf="menu.displayMode==6; else notButton">			
				<calsoft-button-category [menuData]="menu"></calsoft-button-category>
			</div>

	  <ng-template #notButton>
			<span fxLayoutAlign="center center" (click)="goToCategory(menu)" >{{menu.categoryName}}
			<mat-icon  [style.color]="data.categoryColor"  *ngIf="menu.subcategories.length > 0" >arrow_drop_down</mat-icon>
			</span>

			<div class="dropdown-content contentdrop1"
			[ngStyle]="{ 'width': menu.mega === 'false'? 'max-content' : '100%'}"
			*ngIf="menu.subcategories.length > 0"  style="box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);">
				<div class="header" style="  padding-left: 10%;padding-right: 10%;">
					<div fxLayout="row" >
						<div fxLayout-align="center center" fxLayout="column" *ngFor="let submenu1 of menu.subcategories;let odd = odd" style="padding: 10px;">
							<span *ngIf="submenu1.subcategories.length > 0" class="menu-sub1" style="border-bottom: none;"><b> {{submenu1.categoryName }}</b></span>
							<span *ngIf="submenu1.subcategories.length == 0" class="menu-sub1 " (click)="goToCategory(submenu1)" style="border-bottom: none;"><b>{{submenu1.categoryName }}</b></span>
							<div>
								<calsoft-nested-child  [data]="data" [type]="'all'" [list]="submenu1.subcategories"></calsoft-nested-child>							
							</div>
						</div>
					</div>
				</div>   
			</div>

			</ng-template>
		</div>
	</div> 

</ng-container>

<ng-container  *ngIf="data.categoryMenuType==5;">


	<nav class="app-nav" style="white-space: normal;" *ngIf="display" >
	  <ul ngClass.lt-lg="catNames" class="app-nav-list">
  
	  
		  <li class="app-nav-item" *ngFor="let menu of categoryArray" [ngClass]="{'mega': menu.mega != 'false'}">
  
  
			  <div *ngIf="menu.displayMode==6; else notButton">
			  
						<calsoft-button-category [menuData]="menu"></calsoft-button-category>
			  </div>
  
			  <ng-template #notButton>
			  <span *ngIf="data.categoryImageDisplayHomepage==0">
  
				  
				  <ng-container *ngIf="menu.subcategories.length > 0">
					  <a [style.color]="data.categoryColor" (click)="goToCategory(menu)" style="display: flex; align-items: center;font-size: 15px;line-height: 30px;" class="pointer" routerLinkActive="active-link">
						{{menu.categoryName}}
						<mat-icon [style.color]="data.categoryColor" style="font-size: 20px;">keyboard_arrow_down</mat-icon>
					  
					  </a>
					</ng-container>				  
	  
				  <a  [style.color]="data.categoryColor" class="nav-link  pointer" (click)="goToCategory(menu)"
				   *ngIf="menu.subcategories.length == 0" style="font-size: 15px;line-height: 30px;">
					  {{menu.categoryName }}
				  </a>
			  </span>
  
			  <span *ngIf="data.categoryImageDisplayHomepage==1">
				  <div style="display: flex; align-items: center;font-size: 15px;line-height: 30px;" *ngIf="menu.subcategories.length > 0">
					  <img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu.image" [alt]="menu.categoryName">
					  <a [style.color]="data.categoryColor" class="nav-link pointer" (click)="goToCategory(menu)" >{{ menu.categoryName }}</a>
					</div>
	  
				  
				  <div style="display: flex; align-items: center;font-size: 15px;line-height: 30px;" *ngIf="menu.subcategories.length == 0">
					  <img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu.image" [alt]="menu.categoryName">
					  <a [style.color]="data.categoryColor" class="nav-link pointer" (click)="goToCategory(menu)" >{{ menu.categoryName }}</a>
					</div>
			  </span>
	  
  
			  <div class="sub-menu submenu-css" *ngIf="menu.subcategories && menu.subcategories.length >0 "
				  [ngStyle]="{'width': menu.mega == 'false' ? '18%' : ''}">
  
				  <div [style.column-count]="menu.mega != 'false'?data.categroySubmenuColumn:''" >
					  <div *ngFor="let submenu1 of menu.subcategories">
  
						  <ng-container>
							  <div fxLayout="row" style="align-items: center;">
								  <span (click)="goToCategory(submenu1)"
								    class="subMenus2">
									{{submenu1.categoryName}}
								  </span>
								  <!-- <mat-icon style="vertical-align: middle;font-size: 20px;margin-top: 5px;">keyboard_arrow_right</mat-icon> -->
								</div>
								
							  <div fxLayout="row" fxLayoutAlign="start center">
								  <calsoft-nested-child  [data]="data" [type]="'all'" [list]="submenu1.subcategories"></calsoft-nested-child>
							  </div>
						  </ng-container>
					  </div>
				  </div>
			  </div>
		  </ng-template>
		  
		  </li>
	  </ul>
  </nav>
  
  <div fxHide.lt-md *ngIf="!display">
	  <mat-toolbar [color]="color ? 'primary' : null"  class="top-navbar" fxLayoutAlign="center center">
		  <ng-container class="fill-remaining-space" *ngFor="let menu1 of categoryArray;">
  
	  
			  <div *ngIf="menu1.displayMode==6; else notButton">
  
				  
				  <!-- <calsoft-menu-button [data]="menu1"></calsoft-menu-button> -->
							  </div>
  
							  <ng-template #notButton>
			  <span *ngIf="menu1.subcategories.length > 0">
				  <a    class="hover1" mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
					  (click)="openMegaMenu()"><span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName}}</span>
					  <mat-icon [style.color]="data.categoryColor" style="font-size: 20px;" >keyboard_arrow_down</mat-icon>
				  </a>
			  </span>
			  <a mat-button *ngIf="menu1.subcategories.length == 0" routerLinkActive="active-link"
				  (click)="goToCategory(menu1)">
				  <span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName}}</span>
			  </a>
			  <span (mouseleave)="menuTrigger.closeMenu()"></span>
  
			  <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu app-dropdown">
  
				  <div fxLayout="row wrap" fxLayout.xs="column wrap">
  
					  <div [style.column-count]="menu1.mega != 'false'?data.categroySubmenuColumn:''">
  
  
						  <span *ngFor="let submenu1 of menu1.subcategories">
  
							  <a (click)="goToCategory(submenu1)" mat-menu-item
								  routerLink="/"><span [style.color]="data.categoryColor" >{{submenu1.categoryName}}</span>
			  
								  <i [style.color]="data.categoryColor" class="material-icons subMenus">keyboard_arrow_right</i>
							  
							  </a>
  
  
								  <calsoft-nested-child [data]="data" [type]="'ipad'" [list]="submenu1.subcategories"></calsoft-nested-child>
  
							  <!-- <span *ngIf="submenu1.subcategories && submenu1.subcategories.length >0">
  
								  <a *ngFor="let subChild of submenu1.subcategories" (click)="goToCategory(subChild)"
									  mat-menu-item routerLink="/"> {{subChild.categoryName}}</a>
							  </span> -->
  
  
						  </span>
					  </div>
  
				  </div>
  
			  </mat-menu>
			  </ng-template>
		  </ng-container>
	  </mat-toolbar>
  </div>
  
  </ng-container>
<!-- <ng-container *ngIf="data.categoryMenuType==3;">

	<div class="navbar" *ngIf="display">
		<div class="dropdown" *ngFor="let menu of categoryArray" >

			<span  fxLayoutAlign="center center" class="dropbtn"  (click)="goToCategory(menu)">
				<b  [style.color]="data.categoryColor">{{menu.categoryName}}</b>
				<mat-icon  [style.color]="data.categoryColor"  *ngIf="menu.subcategories.length > 0" >arrow_drop_down</mat-icon>
			  </span>

			  
			<div class="dropdown-content contentdrop"
			[ngStyle]="{'left': menu.mega === 'false' ? 'auto' : 
			'6%', 'width': menu.mega === 'false'
			 ? '100px' : 'auto'}"
			*ngIf="menu.subcategories.length > 0">
			<div class="header">
				<div fxLayout="row" >
					<div fxLayout-align="center center" fxLayout="column" *ngFor="let submenu1 of menu.subcategories;let odd = odd" [ngClass]="{'odd-column': odd}">
						<span class="menu-sub" (click)="goToCategory(submenu1)"><b>{{submenu1.categoryName }}</b></span>
						<div>
							<calsoft-nested-child  [data]="data" [type]="'all'" [list]="submenu1.subcategories"></calsoft-nested-child>							
						</div>
					</div>
				</div>
			</div>   
		  </div>
		</div> 
	</div>	  

</ng-container> -->
