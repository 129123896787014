<!-- <div fxLayout="row wrap" fxFlex="100"  [formGroup]="form">

      <ng-container *ngFor="let field of fields;" fxFlex="50">
        <div dynamicField [field]="field" [group]="form"></div>
      </ng-container>

  </div>
   -->
      <!-- <div *ngFor="let field of fields;" [fxFlex]="layout == 2 ? 
    (gapBetweenImage ?  'calc(50% - ' + gapBetweenImage + ')' : '50%') 
    : (layout == 3 ? (gapBetweenImage ? 'calc(33.33% - ' + gapBetweenImage + ')' : '33.33%') 
      : (layout == 4 ? (gapBetweenImage ? 'calc(25% - ' + gapBetweenImage + ')' : '25%') 
        : '100'))" [fxFlex.lt-md]="displayMobileImage == 2 ? (mobileGapBetweenImage ? 'calc(50% - ' + mobileGapBetweenImage + ')' : '50%')
        : (displayMobileImage == 3 ? (mobileGapBetweenImage ? 'calc(33.33% - ' + mobileGapBetweenImage + ')' : '33.33%')
          : (displayMobileImage == 4 ? (mobileGapBetweenImage ? 'calc(25% - ' + mobileGapBetweenImage + ')' : '25%')
            : '100'))" > -->

   <!-- <div fxLayout="row">
    <div fxLayout="row wrap" fxFlex="100" 
       [formGroup]="form" [fxLayoutGap]="gapBetweenImage">
 
           
            <div *ngFor="let field of fields;" 
            [fxFlex]="layout == 2 ? 
    (gapBetweenImage ?  'calc(50% - ' + (gapBetweenImage) + ')' : '50%') 
    : (layout == 3 ? (gapBetweenImage ? 'calc(33.33% - ' + gapBetweenImage + ')' : '33.33%') 
      : (layout == 4 ? (gapBetweenImage ? 'calc(25% - ' + gapBetweenImage + ')' : '25%') 
        : '100'))">
                <div dynamicField [field]="field" [group]="form"></div>
            </div>
        
 
      </div>

   </div> -->

   <div fxLayout="row "  *ngIf="formInit">
    <div fxLayout="row wrap" fxFlex="100" [formGroup]="form" [fxLayoutGap]="gapBetweenImage">
  
      <ng-container *ngFor="let field of fields">
        <ng-container *ngIf="field.inputType !== 'button'; else buttonContainer">
          <div [fxFlex]="layout == 2 ? 
          (gapBetweenImage ?  'calc(50% - ' + (gapBetweenImage) + ')' : '50%') 
          : (layout == 3 ? (gapBetweenImage ? 'calc(33.33% - ' + gapBetweenImage + ')' : '33.33%') 
            : (layout == 4 ? (gapBetweenImage ? 'calc(25% - ' + gapBetweenImage + ')' : '25%') 
              : '100'))">
            <div dynamicField [field]="field" [group]="form"></div>
          </div>
        </ng-container>
        <ng-template #buttonContainer>
          <div fxFlex="100">
            <button (click)="onSubmit()" fxFlex type="submit" style="color:white"
             mat-raised-button class="primary">{{field.label}}</button>
          </div>
        </ng-template>
      </ng-container>
  
    </div>
  </div>
   <!-- <div fxLayout="row" style="box-sizing: border-box;">
    <div fxLayout="row wrap" fxFlex="100" [formGroup]="form" [fxLayoutGap]="gapBetweenImage ? '10px' : '0'">
      <div *ngFor="let field of fields;" fxFlex="50" style="box-sizing: border-box;">
        <div dynamicField [field]="field" [group]="form"></div>
      </div>
    </div>
  </div> -->
  <!-- <div fxLayout="row wrap" fxLayoutGap="10px">
    <div *ngFor="let field of fields;" fxFlex="50">
      <div dynamicField [field]="field" [group]="form"></div>
    </div>
  </div> -->