import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ButtonDialogComponent } from '../button-dialog/button-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'calsoft-button-category',
  templateUrl: './button-category.component.html',
  styleUrls: ['./button-category.component.scss']
})
export class ButtonCategoryComponent {


   constructor(public dialog: MatDialog,
    private router: Router,){


   }

  @Input() menuData: any;


  open(){

    if(this.menuData.description=='0'){

      this.openMatDialog();

    }else{

      this.link(this.menuData.metaDescription)

    }
  }

  link(url) {
    this.router.navigate([url]);
  }
  


  openMatDialog() {
    if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(ButtonDialogComponent, {
        width: '790px',
        data: { value: this.menuData }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
       


      });

    }
    else {
      let dialogRef = this.dialog.open(ButtonDialogComponent, {
        minWidth: '100vw', height: '100vh',
        data: { page: this.menuData, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
  


      });
    }

  }
}
