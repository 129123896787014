<footer class="footer-v1" style="max-width: 100%;">
    <div class="theme-container">
       <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around start" class="py-1 border-bottom-mute">
        <div fxFlex="10"></div>
        <div fxFlex="25">
              <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
              <h3 class="col-title">CALIFORNIA SOFTWARE COMPANY LIMITED,
                Workflo Greeta Towers, Industrial Estate, Perungudi, OMR Phase 1, Chennai - 600096,
                Tamil Nadu, India</h3>
                <h3>Contact : {{ data.storePhoneNumber }}</h3>
        </div>

        <div  fxFlex="15" ngClass.xs="mt-2" style="padding-left: 4%;">
            <h3 class="col-title">Home</h3>
            <h3 class="col-title">Demo</h3>
            <h3 class="col-title">Features</h3>
            <h3 class="col-title">Pricing</h3>            
            <h3 class="col-title">Partner</h3>            
        </div>
        <div  fxFlex="15" ngClass.xs="mt-2" style="padding-left: 4%;">
            <h3 class="col-title">dSPEEDUp</h3>
            <h3 class="col-title">Marketing</h3>
            <h3 class="col-title">Pharmacy Website</h3>
            <h3 class="col-title">Business Name Generator</h3>            
        </div>
        <div  fxFlex="13" ngClass.xs="mt-2" style="padding-left: 4%;">
            <h3 class="col-title">Careers</h3>
            <h3 class="col-title">Our Story</h3>
            <h3 class="col-title"><a routerLink="/contact-us" >Contact US</a></h3>
            <h3 class="col-title">GiveWay</h3>            
        </div>
        <div  fxFlex="12" ngClass.xs="mt-2" style="padding-left: 4%;">
            <h3 class="col-title"><a routerLink="/blog" >Blog</a></h3>
            <h3 class="col-title">FAQs</h3>
            <h3 class="col-title"><a routerLink="/privacy" >Privacy Policy</a></h3>            
        </div>

        <div fxFlex="10"></div>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" class="border-bottom-mute">
        <div fxFlex="10"></div>
        <div fxFlex.lt-md="50" fxFlex.gt-md="30">
            <div *ngIf="showFooterIcon==1">
                <span *ngFor="let item of footerIconData; let i = index">
          
                  <button (click)="navigate(item)" mat-icon-button>
          
                    <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                  </button>
                </span>
          
              </div>
        </div>
        <div fxFlex.lt-md="40" fxFlex.gt-md="60">
            <h3 class="mt8">© CALIFORNIA SOFTWARE COMPANY LIMITED. All rights reserved.</h3>
        </div>
    </div>
    <p class="mt-1" style="text-align: center;"><a routerLink="/terms&conditions" >Terms & Conditions</a></p>

      </div>
    
      <!-- <div *ngIf="footerImageEnable==1">
      <div *ngIf="display">
        <div style="margin-top:auto;" fxHide.lt-md>
          <img [src]="serverPath+footerLogoSrc" onerror="this.src='';" style="width: 100%;"/>
        </div>
      </div>
      </div> -->
      
    </footer>