<div *ngIf="display">
<mat-toolbar  fxFlex style="border-bottom: 1px solid #cbc0c0;"  
fxHide  fxShow.gt-md  class="primary app-toolbar bg" >

<div  class="theme-container" fxLayoutAlign="space-between center"  > 
    <mat-toolbar-row >
        <div  class="theme-container" fxLayout="row" fxLayoutGap="10px" fxFlex="20" >

            <div fxFlex="5">
    
            </div>
    
            <section   fxLayoutAlign="center center">
              
                <calsoft-logo  fxFlex="70" [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
    
            </section>
         
        </div>

        <div   class="theme-container"  fxLayout="row" fxLayoutAlign="end center">
      
            <calsoft-Menu [color]="true" fxLayoutAlign="center center"   style="z-index: 1000;" [data]="data" [type]="'type'" [categoryArray]="item"></calsoft-Menu>
            <div *ngIf="data.disableLoginHeader==0">
            <div *ngIf="!userLoggedIn"(click)="SignIn('login')" fxLayoutAlign="end center">
                <button mat-flat-button  class="primary"  style="color: white" *ngIf="!userLoggedIn">LOGIN/SIGNUP
                </button>
            </div>
            </div>
    
            <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center"   *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
    
            <div fxLayoutAlign="end center"  *ngIf="url != '/cart'">
                <calsoft-cart-icon [color]="false" ></calsoft-cart-icon>
            </div>
            
        </div>
    </mat-toolbar-row>


    <!-- <div fxFlex fxHide fxShow.gt-sm class="list-flex user-tool"> -->
     
</div>

     

</mat-toolbar>
<mat-toolbar fxHide fxShow.gt-md >

</mat-toolbar>
</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3  [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header3>
        </div>

</div>


<!-- <calsoft-mobile-header-fixed [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

</calsoft-mobile-header-fixed> -->
<!-- Mobile View  end -->

