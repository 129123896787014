import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(  private http: HttpClient) { }

  url = environment.serverAPI4 + "/api/catalog/contact";

  url1= environment.serverAPI + "/api/catalog/form/lablel";

  url2= environment.serverAPI + "/api/catalog/form/submission";

  url3 = environment.serverAPI2 + "/api/ig/referralCustomer"; 

  createContact(contact: any): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url}`, contact)
  }



  getDynmaicForm(formGroupId:any): Observable<Object> {
    return this.http.get(`${this.url1}/${formGroupId}`);
		
	}

  saveForm(object: any): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url2}`, object);
  }


  referralCustomer(referral: any): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url3}`, referral);
  }

}
